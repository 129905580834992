import { type SearchCategory } from '@kijiji/generated/graphql-types'

import {
  BUY_SELL_L1,
  BUY_SELL_L2,
  BUY_SELL_L3,
  CARS_VEHICLES_CATEGORIES,
  CARS_VEHICLES_L1,
  CARS_VEHICLES_L2,
  CARS_VEHICLES_L3,
  CATEGORIES,
  COMMUNITY_L1,
  COMMUNITY_L2,
  COMMUNITY_L3,
  FURNITURE_CATEGORY,
  JOBS_L1,
  JOBS_L2,
  JOBS_L3,
  L1Categories,
  L2Categories,
  PETS_L1,
  PETS_L2,
  PETS_L3,
  REAL_ESTATE_L1,
  REAL_ESTATE_L2,
  REAL_ESTATE_L3,
  SERVICES_L1,
  SERVICES_L2,
  SERVICES_L3,
  VACATION_RENTALS_L1,
  VACATION_RENTALS_L2,
  VACATION_RENTALS_L3,
} from './constants'

/**
 * Checks if the given categoryId is part of any Buy and Sell category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is part of Buy and Sell categories, otherwise false.
 */
export const isAnyBuyAndSellCategory = (categoryId: number) =>
  [BUY_SELL_L1, ...BUY_SELL_L2, ...BUY_SELL_L3].includes(categoryId)

/**
 * Checks if the given categoryId is part of any Cars and Vehicles category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is part of Cars and Vehicles categories, otherwise false.
 */
export const isAnyCarsVehiclesCategory = (categoryId: number) =>
  [CARS_VEHICLES_L1, ...CARS_VEHICLES_L2, ...CARS_VEHICLES_L3].includes(categoryId)

/**
 * Checks if the given categoryId is part of any Community category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is part of Community categories, otherwise false.
 */
export const isAnyCommunityCategory = (categoryId: number) =>
  [COMMUNITY_L1, ...COMMUNITY_L2, ...COMMUNITY_L3].includes(categoryId)

/**
 * Checks if the given categoryId is part of any Jobs category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is part of Jobs categories, otherwise false.
 */
export const isAnyJobsCategory = (categoryId: number) =>
  [JOBS_L1, ...JOBS_L2, ...JOBS_L3].includes(categoryId)

/**
 * Checks if the given categoryId is part of any Pets category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is part of Pets categories, otherwise false.
 */
export const isAnyPetsCategory = (categoryId: number) =>
  [PETS_L1, ...PETS_L2, ...PETS_L3].includes(categoryId)

export const isDogsOrCatsRehomingCategory = (categoryId: number) =>
  [CATEGORIES.DOGS_PUPPIES_FOR_SALE_CATEGORY_ID, CATEGORIES.CATS_KITTENS_FOR_SALE_CATEGORY_ID].includes(
    categoryId
  )

/**
 * Checks if the given categoryId is part of any Real Estate category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is part of Real Estate categories, otherwise false.
 */
export const isAnyRealEstateCategory = (categoryId: number) =>
  [REAL_ESTATE_L1, ...REAL_ESTATE_L2, ...REAL_ESTATE_L3].includes(categoryId)


/**
 * Checks if the given categoryId is part of Real Estate L3 categories (sell or rent but not RE services).
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is part of Real Estate L3 categories, otherwise false.
 */
export const isRealEstateSellOrRentCategory = (categoryId: number) =>
  [...REAL_ESTATE_L3].includes(categoryId)

/**
 * Checks if the given categoryId is part of any Services category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is part of Services categories, otherwise false.
 */
export const isAnyServicesCategory = (categoryId: number) => {
  /** Categories outside of Service node, but still "SERVICES" categories */
  const otherServices = [
    CATEGORIES.REAL_ESTATE_SERVICES_CATEGORY_ID,
    CATEGORIES.AUTOMOTIVE_SERVICES_CATEGORY_ID,
    CATEGORIES.ANIMAL_PET_SERVICES_CATEGORY_ID,
    CATEGORIES.CELL_PHONE_SERVICES_CATEGORY_ID,
  ]

  return [SERVICES_L1, ...SERVICES_L2, ...SERVICES_L3, ...otherServices].includes(categoryId)
}

/**
 * Checks if the given categoryId is part of any Vacation Rentals category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is part of Vacation Rentals categories, otherwise false.
 */
export const isAnyVacationRentalsCategory = (categoryId: number) =>
  [VACATION_RENTALS_L1, ...VACATION_RENTALS_L2, ...VACATION_RENTALS_L3].includes(categoryId)

/**
 * Checks if the given categoryId is the Apartments and Condos category (Long Term Rentals).
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Apartments and Condos category, otherwise false.
 */
export const isLongTermRentalsCategory = (categoryId: number) =>
  categoryId === CATEGORIES.APARTMENTS_CONDOS_CATEGORY_ID

/**
 * Checks if the given categoryId is the Auto Parts category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Auto Parts category, otherwise false.
 */
export const isAutoPartsCategory = (categoryId: number) =>
  categoryId === CARS_VEHICLES_CATEGORIES.VEHICLE_PARTS

/**
 * Checks if the given categoryId is the Automotive Services category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Automotive Services category, otherwise false.
 */
export const isAutomotiveServicesCategory = (categoryId: number) =>
  categoryId === CATEGORIES.AUTOMOTIVE_SERVICES_CATEGORY_ID

/**
 * Checks if the given categoryId is the Autos category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Autos category, otherwise false.
 */
export const isAutosCategory = (categoryId: number) =>
  categoryId == CARS_VEHICLES_CATEGORIES.CARS_TRUCKS ||
  categoryId === CARS_VEHICLES_CATEGORIES.CLASSIC_CARS

/**
 * Checks if the given categoryId is the Cars Trucks category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Cars Trucks category, otherwise false.
 */
export const isCarsTrucks = (categoryId: number) =>
  categoryId === CARS_VEHICLES_CATEGORIES.CARS_TRUCKS

/**
 * Checks if the given categoryId is the Motorcycles category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Motorcycles category, otherwise false.
 */
export const isMotorcycles = (categoryId: number) =>
  categoryId === CARS_VEHICLES_CATEGORIES.MOTORCYCLES

/**
 * Checks if the given categoryId is the Cars Vehicles category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Cars Vehicles category, otherwise false.
 */
export const isCarsVehiclesCategory = (categoryId: number) => categoryId === CARS_VEHICLES_L1

/**
 * Checks if the given categoryId is the Buy and Sell category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Buy and Sell category, otherwise false.
 */
export const isBuyAndSellCategory = (categoryId: number) => categoryId === BUY_SELL_L1

/**
 * Checks if the given categoryId is the Community category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Community category, otherwise false.
 */
export const isCommunityCategory = (categoryId: number) => categoryId === COMMUNITY_L1

/**
 * Checks if the given categoryId is the Jobs category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Jobs category, otherwise false.
 */
export const isJobsCategory = (categoryId: number) => categoryId === JOBS_L1

/**
 * Checks if the given categoryId is the Pets category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Pets category, otherwise false.
 */
export const isPetsCategory = (categoryId: number) => categoryId === PETS_L1

/**
 * Checks if the given categoryId is the Real Estate category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Real Estate category, otherwise false.
 */
export const isRealEstateCategory = (categoryId: number) => categoryId === REAL_ESTATE_L1

/**
 * Checks if the given categoryId is the Services category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Services category, otherwise false.
 */
export const isServicesCategory = (categoryId: number) => categoryId === SERVICES_L1

/**
 * Checks if the given categoryId is the Vacation Rentals category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the Vacation Rentals category, otherwise false.
 */
export const isVacationRentalsCategory = (categoryId: number) => categoryId === VACATION_RENTALS_L1

/**
 * Checks if the given categoryId is part of the extended radius for Cars and Vehicles category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is part of the extended radius for Cars and Vehicles category, otherwise false.
 */
export const isCarsVehiclesExtendedRadiusCategory = (categoryId: number) => {
  if (isAutoPartsCategory(categoryId)) return false
  if (isAutomotiveServicesCategory(categoryId)) return false

  return [CARS_VEHICLES_L1, ...CARS_VEHICLES_L2, ...CARS_VEHICLES_L3].includes(categoryId)
}

/**
 * Checks if the given category is part of any Furniture category.
 * @param category - The category object to check.
 * @returns True if the category is part of any Furniture category, otherwise false.
 */
export const isAnyFurnitureCategory = (category: SearchCategory) =>
  category.id === FURNITURE_CATEGORY || category.parentId === FURNITURE_CATEGORY

/**
 * Checks if the given categoryId is an L1 category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is an L1 category, otherwise false.
 */
export const isL1Category = (categoryId: number) => L1Categories.includes(categoryId)

/**
 * Checks if the given categoryId is the root category.
 * @param categoryId - The ID of the category to check.
 * @returns True if the categoryId is the root category, otherwise false.
 */
export const isAllCategories = (categoryId: number) => categoryId == CATEGORIES.ROOT_CATEGORY_ID

/**
 * Checks if the given category is an L2 category.
 * @param params - An object containing categoryId and parentId.
 * @param params.categoryId - The ID of the category to check.
 * @param params.parentId - The parent ID of the category to check.
 * @returns True if the category is an L2 category, otherwise false.
 */
export const isL2Category = ({
  categoryId,
  parentId,
}: {
  categoryId?: number
  parentId?: number
}) =>
  parentId
    ? L1Categories.includes(parentId)
    : categoryId
      ? L2Categories.includes(categoryId)
      : false

/**
 * Checks if the given categoryId is an L3 category.
 * @param categoryId - The ID of the category to check.
 * @param parentId - The parent ID of the category to check (optional).
 * @returns True if the categoryId is an L3 category, otherwise false.
 */
export const isL3Category = (categoryId: number, parentId?: number) =>
  !isL1Category(categoryId) &&
  !isL2Category({ categoryId }) &&
  (!parentId || isL2Category({ categoryId: parentId }))

/**
 * Checks if a given category ID is considered as all categories except verticals (autos and real estate)
 *
 * This function evaluates if the provided `categoryId` falls under
 * any of the following categories: all categories, buy and sell,
 * community, jobs, pets, services, or vacation rentals.
 *
 * @param {number} categoryId - The ID of the category to check.
 * @returns {boolean} - Returns `true` if the `categoryId` belongs to any of the specified categories, otherwise returns `false`.
 *
 * @example
 * // Check if the category ID is considered as all categories except verticals
 * const result = isAllCategoriesExceptVerticals(123);
 * console.log(result); // Output: true or false
 */
export const isAllCategoriesExceptVerticals = (categoryId: number) => {
  return (
    isAllCategories(categoryId) ||
    isAnyBuyAndSellCategory(categoryId) ||
    isAnyCommunityCategory(categoryId) ||
    isAnyJobsCategory(categoryId) ||
    isAnyPetsCategory(categoryId) ||
    isAnyServicesCategory(categoryId) ||
    isAnyVacationRentalsCategory(categoryId)
  )
}

export const isVerticalCategory = (categoryId: number) => {
  return (
    (isAnyRealEstateCategory(categoryId) || isAnyCarsVehiclesCategory(categoryId)) &&
    categoryId !== CATEGORIES.REAL_ESTATE_SERVICES_CATEGORY_ID
  )
}
